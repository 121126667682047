import {objArrMapObj} from "@/untils/otherEvent"

/** 对版模型特征收集 */
export const duibanModelLabelTableColumns = [
    { title: "原图", align: "center", scopedSlots: { customRender: "itemCutPicSlot" }, width: 500 },
    { title: "裁剪图", align: "center", scopedSlots: { customRender: "itemCutPicImgSlot" }, width: 100 },
    { title: "标记", align: "center", scopedSlots: { customRender: "itemConfirmStatusSlot" }, width: 50 },
    { title: "钱币类型", align: "center", scopedSlots: { customRender: "itemCoinTypeSlot" }, width: 150 },
    { title: "正面面文", align: "center", scopedSlots: { customRender: "itemImgFrontOrBackSlot" }, width: 150 },
    { title: "正反面", align: "center", scopedSlots: { customRender: "itemTagByFrontOrBackSlot" }, width: 120 },
    { title: "清晰度", align: "center", scopedSlots: { customRender: "itemImgQualitySlot" }, width: 120 },
    { title: "角度", align: "center", scopedSlots: { customRender: "itemImgAngleSlot" }, width: 120 },
    { title: "锈迹", align: "center", scopedSlots: { customRender: "itemImgRustStatusSlot" }, width: 120 },
    { title: "标注员", align: "center", scopedSlots: { customRender: "itemModelLabelAdminSlot" }, width: 150 },
    // { title: "特征", align: "center", scopedSlots: { customRender: "itemImgFeatureSlot" }, width: 200 },
    // { title: "会昌", align: "center", scopedSlots: { customRender: "itemImgKaiYuanStatusSlot" }, width: 120 },
    // { title: "铸局", align: "center", scopedSlots: { customRender: "itemImgCreateCoinTypeSlot" }, width: 120 },
    // { title: "坑口", align: "center", scopedSlots: { customRender: "itemImgUnearthedRelicSlot" }, width: 120 },
    { title: "操作", align: "center", scopedSlots: { customRender: "itemAuctionSlot" }, },
]

/** 已标记，未标记 */
export const confirmStatusTypeList = [
    { name: '已标记', value: 1 },
    { name: '不确定', value: -1 },
    { name: '未标记', value: 0 },
]
/** 央视类 */
export const confirmStatusTypeClassType = {
    0: 'color-red',
    '-1': 'color-gray',
    1: 'color-green'
}
/** 正反面选择 正反面  1 正面  2 反面 3 侧面*/
export const frontBackSideList = [
    { name: '正面', value: 1 },
    { name: '反面', value: 2 },
    { name: '侧面', value: 3 },
]
/** 清晰度   1 正常  2 模糊  3 反光*/
export const clarityList = [
    { name: '正常', value: 1 },
    { name: '模糊', value: 2 },
]
/** 角度   1 正常  2 倾斜*/
export const angleList = [
    { name: '正常', value: 1 },
    { name: '倾斜', value: 2 },
]
/** 锈迹   1 无锈  2 重锈*/
export const rustList = [
    { name: '正常', value: 1 },
    { name: '重锈', value: 2 },
]

export const confirmStatusTypeMapText = (value) => {
    return objArrMapObj('value', 'name', confirmStatusTypeList)[value]
}

/** 钱币类型 */
export const COIN_TYPE_LIST = [
    { name: '方孔圆钱', value: 'circle_coin' },
    { name: '蚁鼻钱', value: 'ant_coin' },
    { name: '机制币', value: 'machine_coin' },
    // { name: '铲钱', value: 'spade_coin' },
    // { name: '鱼币', value: 'fish_coin' },
    // { name: '贝币', value: 'shell_coin' },
    // { name: '刀币', value: 'knife_coin' },
    // { name: '纸币', value: 'paper_coin' },
    // { name: '银锭', value: 'ingot_coin' },
    // { name: '其他钱币', value: 'other_coin' },
    // { name: '文字', value: 'text' },
    // { name: '二维码', value: 'qrcode' },
    // { name: '条形码', value: 'barcode' },
]

export const POST_DATA_JSON = {
    // 方孔圆钱
    circle_coin: {
        "正面面文": "",
        "正反面": "",
        "清晰度": "",
        "角度": "",
        "特征": "",
        "会昌": "",
        "铸局": "",
        "锈迹": "",
        "坑口": "",
    },
    // 蚁鼻钱
    ant_coin: {
        "正面面文": "",
        "正反面": "",
        "清晰度": "",
        "角度": "",
        "特征": "",
        "锈迹": "",
    },
    // 机制币
    machine_coin: {
        "正面面文": "",
        "正反面": "",
        "清晰度": "",
        "角度": "",
        "特征": "",
        "锈迹": "",
    }
}



/** 方孔圆钱JSON */
export const COIN_TYPE_MODEL_LABEL_JSON_CIRCLE_COIN = {
    "正面面文": [],
    "正反面": ["正面","反面","侧面"],
    "清晰度": ["正常","模糊"],
    "角度": ["正常","倾斜"],
    "特征": ["无","花穿","重轮","移范","兩丝纹","毛边","刻花","结晶","朱砂"],
    "会昌": [
        "晋","蝌蚪文","背星月","背双月","光背","梓","越","永","益","兖","宣","兴",
        "襄","潭","润","平","洛","梁","蓝","荆","京","洪","桂","广","福","鄂","丹",
        "昌"
    ],
    "铸局": ["无","宝泉","宝源","宝浙","宝直","宝巩","宝昌"],
    "锈迹": ["正常", '重锈'],
    "坑口": [
        "无","生坑","熟坑","传世","祈福坑","龙亭坑","武威坑","西溪坑","高邮坑","成都坑",
        "腾冲坑","定州坑","灵石坑","北京坑","邯郸坑","桂林坑","辉县坑","苏州坑","绵阳坑",
        "丹阳坑","菏泽坑","内蒙坑","大同坑"
    ],
}
/** 蚁鼻钱 */
export const COIN_TYPE_MODEL_LABEL_JSON_ANT_COIN = {
    "正面面文": [],
    "正反面": ["正面","反面","侧面"],
    "清晰度": ["正常","模糊"],
    "角度": ["正常","倾斜"],
    "特征": ["无","毛边",],
    "锈迹": ["正常", '重锈'],
}

/** 机制币 */
export const COIN_TYPE_MODEL_LABEL_JSON_MACHINE_COIN = {
    "正面面文": [],
    "正反面": ["正面","反面","侧面"],
    "清晰度": ["正常","模糊"],
    "角度": ["正常","倾斜"],
    "特征": ["环彩","墨戳","纸戳","字戳"],
    "锈迹": ["正常", '重锈'],
}

/** 模型编辑手机JSON */
export const RECOG_MODEL_LABEL_JSON = {
    circle_coin: COIN_TYPE_MODEL_LABEL_JSON_CIRCLE_COIN,
    ant_coin: COIN_TYPE_MODEL_LABEL_JSON_ANT_COIN,
    machine_coin: COIN_TYPE_MODEL_LABEL_JSON_MACHINE_COIN,
}