<template>
  <a-modal
      :width="900"
      v-model="showPopup"
      :title="`编辑模型特征---${jsonKey}`"
      @cancel="handleCancel"
      @ok="handleConfirm"
  >
    <template slot="footer" v-if="jsonKey !== '正面面文'">
      <div></div>
    </template>
<!--  大币种修改  -->
    <div class="w-100-w" v-if="jsonKey === '正面面文'">
      <a-select
          @search="searchBigCoinData"
          v-model="postJson['正面面文']"
          :filter-option="untils.filterOption"
          showSearch
          class="w-400"
          placeholder="请选择正面面文或者无正面面文"
      >
        <a-select-option
            v-for="(item, index) of bigCoinList"
            :key="index"
            :value="item.coinKindName"
        >{{ item.coinKindName }}</a-select-option>
      </a-select>
    </div>
<!--  tag选择  -->
    <div class="w-100-w" v-else>
      <div class="flex-start-center flex-wrap">
        <a-tag
            v-for="(tag, key) in recogModelLabelJson[coinType][jsonKey]"
            :key="key"
            class="cur-pot mb-5 w-80 h-30 mr-10 font-size-14 text-center color-black"
            :class="tag === postJson[jsonKey] ? 'color-blue' : ''"
            :color="tag === postJson[jsonKey] ? 'blue' : ''"
            @click="handleConfirm(tag, jsonKey)"
        >{{ tag }}</a-tag>
      </div>
    </div>
  </a-modal>
</template>
<script>
import {postModelLabelInfoApi} from "@/views/cmsPage/versionManage/duibanModelLabel/_apis"
import {POST_DATA_JSON, RECOG_MODEL_LABEL_JSON} from "@/views/cmsPage/versionManage/duibanModelLabel/_data"
import untils from "@/untils"
import {getBigCoinList} from "@/axios/apis"
import {computedMustParamsByPopup} from "@/views/cmsPage/versionManage/duibanModelLabel/_service"
export default {
  components: {},
  data() {
    return {
      untils,
      recogModelLabelJson: RECOG_MODEL_LABEL_JSON,
      showPopup: false,
      jsonKey: '',
      selectedJsonValue: '',
      postDataJson: POST_DATA_JSON,
      postJson: {},
      bigCoinList: [],
      editorId: '',
      coinType: 'circle_coin',
      mustParams: {
        coinId: 0,
        frontBackSide: 0,
        clarity: 0,
        angle: 0,
        rust: 0
      }
    };
  },
  methods: {
    async show(data, jsonKey, mustParams) {
      this.editorId = data.id
      this.coinType = data.coinType
      this.postJson = JSON.parse(JSON.stringify(this.postDataJson[this.coinType]))
      Object.assign(this.mustParams, mustParams)
      if (data.labelValue) {
        Object.assign(this.postJson, JSON.parse(data.labelValue))
      }
      this.jsonKey = jsonKey
      if (!this.postJson['正面面文']) {
        if (data.coinName) {
          this.postJson[this.jsonKey] = data.coinName
        } else {
          this.postJson[this.jsonKey] = undefined
        }
      }
      if (jsonKey === '正面面文') {
        await this.searchBigCoinData(this.postJson[this.jsonKey])
      }
      this.showPopup = true
    },
    /** 提交数据 */
    async handleConfirm(tag) {
      if (this.jsonKey !== '正面面文') {
        if (this.postJson[this.jsonKey] === tag) {
          this.postJson[this.jsonKey] = ''
        } else {
          this.postJson[this.jsonKey] = tag
        }
      }
      if (this.jsonKey === '正面面文') {
        if (!this.postJson[this.jsonKey]) return this.$message.warn(`请选择【${this.jsonKey}】`)
      }
      this.$loading.show()
      // 计算必传属性
      const tempJsonValue = this.jsonKey === '正面面文' ? (this.bigCoinList.find(el => el.coinKindName === this.postJson[this.jsonKey]).id || '')  : this.mustParams.coinId
      const tempMustParams = computedMustParamsByPopup(this.jsonKey, tempJsonValue, this.mustParams)
      const res = await postModelLabelInfoApi({
        id: this.editorId,
        coinType: this.coinType,
        ...tempMustParams,
        attributeVo: this.postJson
      })
      this.$loading.hide()
      if (res.status !== '200') return
      this.$emit('editSuccess', {
        id: this.editorId,
        json: this.postJson
      })
      this.handleCancel()
    },
    /** 取消 */
    handleCancel() {
      this.showPopup = false
      this.postJson = {}
      this.coinType = 'circle_coin'
      this.mustParams = {
        coinId: 0,
        frontBackSide: 0,
        clarity: 0,
        angle: 0,
        rust: 0
      }
    },
    /** 查询大币种 */
    async searchBigCoinData(str) {
      const res = await getBigCoinList(str)
      this.$loading.hide()
      if (res.status !== '200') return
      const { records } = res.data;
      records.unshift({ coinKindName: '无', id: 0})
      this.bigCoinList = records;
    }
  },
};
</script>

<style lang="scss" scoped>

</style>
