<template>
  <div>
    <a-table
        :columns="tableList"
        :pagination="pagination"
        :data-source="list"
        :rowKey="(record, index) => index"
        @change="changePage"
    >
<!--   裁剪图   -->
      <div slot="itemCutPicSlot" slot-scope="row">
        <img class="w-500" :src="row.itemImage" alt="" @click="handlePreviewImg([row.itemImage], 0)">
        <div class="mt-10 color-gray">【{{ row.id }}】{{ row.coinName }}</div>
      </div>
<!--   裁剪图   -->
      <div slot="itemCutPicImgSlot" slot-scope="row">
        <img class="w-100 h-100" :src="row.cutItemImage" alt="" @click="handlePreviewImg([row.cutItemImage], 0)">
      </div>
<!--   是否标记   -->
      <div slot="itemConfirmStatusSlot" slot-scope="row">
        <div :class="confirmStatusTypeClassType[row.isConfirm]">{{ computedConfirmStatusTypeMapText(row.isConfirm) }}</div>
      </div>
<!--  钱币类型    -->
      <div slot="itemCoinTypeSlot" slot-scope="row">
        <template v-if="row.coinType">
          <a-popconfirm
              title="重新修改类型会覆盖之前的标注记录，需要重新标注！，是否确定修改？"
              ok-text="Yes"
              cancel-text="No"
              v-for="(tag, key) in coinTypeList"
              :key="key"
              @confirm="handleConfirm(tag, 'coinType', row)"
          >
            <a-tag
                class="cur-pot mb-5 w-80 h-30 mr-10 font-size-14 text-center color-black"
                :class="tag.value === row.coinType ? 'color-blue' : ''"
                :color="tag.value === row.coinType ? 'blue' : ''"
            >{{ tag.name }}</a-tag>
          </a-popconfirm>
        </template>
        <template v-else>
          <a-tag
              v-for="(tag, key) in coinTypeList"
              :key="key"
              class="cur-pot mb-5 w-80 h-30 mr-10 font-size-14 text-center color-black"
              :class="tag.value === row.coinType ? 'color-blue' : ''"
              :color="tag.value === row.coinType ? 'blue' : ''"
              @click="handleConfirm(tag, 'coinType', row)"
          >{{ tag.name }}</a-tag>
        </template>
      </div>
<!--  正面面文    -->
      <div slot="itemImgFrontOrBackSlot" slot-scope="row" v-if="row.coinType">
        <div class="mt-10">
          <div v-if="computedModelLabelStrToJson(row.labelValue, '正面面文')" class="color-blue font-weight-bold">{{ computedModelLabelStrToJson(row.labelValue, '正面面文') }}</div>
          <div v-else>{{ row.coinName }} <span class="color-green" v-if="row.coinPercentage">（{{ row.coinPercentage }}）</span> </div>
          <a-button
              class="mt-10" type="primary"
              size="small" icon="edit"
              @click="handleChangeEditor(row, '正面面文')"
          >编辑正面面文</a-button>
          <div class="mt-10">
            <a-popconfirm
                title="确定标记为不确定？"
                ok-text="Yes"
                cancel-text="No"
                @confirm="handleNotConfirm(row.id)"
            >
              <a-button type="danger" size="small">不确定</a-button>
            </a-popconfirm>
          </div>
        </div>
      </div>
<!--  正反面    -->
      <div slot="itemTagByFrontOrBackSlot" slot-scope="row" v-if="row.coinType">
        <div class="mb-10 color-red">正反面</div>
        <a-tag
            v-for="(tag, key) in recogModelLabelJson[row.coinType]['正反面']"
            :key="key"
            class="cur-pot mb-5 w-80 h-30 mr-10 font-size-14 text-center color-black"
            :class="tag === computedModelLabelStrToJson(row.labelValue, '正反面') ? 'color-blue' : ''"
            :color="tag === computedModelLabelStrToJson(row.labelValue, '正反面') ? 'blue' : ''"
            @click="handleConfirm(tag, '正反面', row)"
        >{{ tag }}</a-tag>
      </div>
<!--   清晰度   -->
      <div slot="itemImgQualitySlot" slot-scope="row" v-if="row.coinType">
        <div class="mb-10 color-red">清晰度</div>
        <a-tag
            v-for="(tag, key) in recogModelLabelJson[row.coinType]['清晰度']"
            :key="key"
            class="cur-pot mb-5 w-80 h-30 mr-10 font-size-14 text-center color-black"
            :class="tag === computedModelLabelStrToJson(row.labelValue, '清晰度') ? 'color-blue' : ''"
            :color="tag === computedModelLabelStrToJson(row.labelValue, '清晰度') ? 'blue' : ''"
            @click="handleConfirm(tag, '清晰度', row)"
        >{{ tag }}</a-tag>
      </div>
<!--   角度   -->
      <div slot="itemImgAngleSlot" slot-scope="row" v-if="row.coinType">
        <div class="mb-10 color-red">角度</div>
        <a-tag
            v-for="(tag, key) in recogModelLabelJson[row.coinType]['角度']"
            :key="key"
            class="cur-pot mb-5 w-80 h-30 mr-10 font-size-14 text-center color-black"
            :class="tag === computedModelLabelStrToJson(row.labelValue, '角度') ? 'color-blue' : ''"
            :color="tag === computedModelLabelStrToJson(row.labelValue, '角度') ? 'blue' : ''"
            @click="handleConfirm(tag, '角度', row)"
        >{{ tag }}</a-tag>
      </div>
<!--   特征   -->
      <div slot="itemImgFeatureSlot" slot-scope="row" v-if="row.coinType">
        <a-tag
            v-for="(tag, key) in recogModelLabelJson[row.coinType]['特征']"
            :key="key"
            class="cur-pot mb-5 w-80 h-30 mr-10 font-size-14 text-center color-black"
            :class="tag === computedModelLabelStrToJson(row.labelValue, '特征') ? 'color-blue' : ''"
            :color="tag === computedModelLabelStrToJson(row.labelValue, '特征') ? 'blue' : ''"
            @click="handleConfirm(tag, '特征', row)"
        >{{ tag }}</a-tag>
      </div>
<!--   锈迹   -->
      <div slot="itemImgRustStatusSlot" slot-scope="row" v-if="row.coinType">
        <div class="mb-10 color-red">锈迹</div>
        <a-tag
            v-for="(tag, key) in recogModelLabelJson[row.coinType]['锈迹']"
            :key="key"
            class="cur-pot mb-5 w-80 h-30 mr-10 font-size-14 text-center color-black"
            :class="tag === computedModelLabelStrToJson(row.labelValue, '锈迹') ? 'color-blue' : ''"
            :color="tag === computedModelLabelStrToJson(row.labelValue, '锈迹') ? 'blue' : ''"
            @click="handleConfirm(tag, '锈迹', row)"
        >{{ tag }}</a-tag>
      </div>
<!--   会昌   -->
      <div slot="itemImgKaiYuanStatusSlot" slot-scope="row" v-if="row.coinType">
        <div>{{ computedModelLabelStrToJson(row.labelValue, '会昌') }}</div>
        <a-button
            v-if="row.coinId === 1"
            class="mt-10" type="primary"
            size="small" icon="edit"
            @click="handleChangeEditor(row, '会昌')"
        >编辑会昌</a-button>
      </div>
<!--  铸局    -->
      <div slot="itemImgCreateCoinTypeSlot" slot-scope="row" v-if="row.coinType">
        <div>{{ computedModelLabelStrToJson(row.labelValue, '铸局') }}</div>
        <a-button
            v-if="['circle_coin'].includes(row.coinType)"
            class="mt-10" type="primary"
            size="small" icon="edit"
            @click="handleChangeEditor(row, '铸局')"
        >编辑铸局</a-button>
      </div>
<!--  坑口    -->
      <div slot="itemImgUnearthedRelicSlot" slot-scope="row" v-if="row.coinType">
        <div>{{ computedModelLabelStrToJson(row.labelValue, '坑口') }}</div>
        <a-button
            v-if="['circle_coin'].includes(row.coinType)"
            class="mt-10" type="primary"
            size="small" icon="edit"
            @click="handleChangeEditor(row, '坑口')"
        >编辑坑口</a-button>
      </div>
<!-- 标注员     -->
      <div slot="itemModelLabelAdminSlot" slot-scope="row">
        <div class="color-green" v-if="row.sysAdmin">{{ row.sysAdmin }}</div>
      </div>
<!--  操作    -->
      <div slot="itemAuctionSlot" slot-scope="row">
        <a-popconfirm
            title="确定删除当前标记？"
            ok-text="Yes"
            cancel-text="No"
            @confirm="handleDelLabel(row.id)"
        >
          <a-button type="danger" size="small">删除</a-button>
        </a-popconfirm>
      </div>
    </a-table>
<!--  编辑相关  -->
    <EditorModelLabelPopup ref="editorModelLabelPopupEl" @editSuccess="handleEditorSuccess"/>
  </div>
</template>
<script>
import {
  COIN_TYPE_LIST,
  confirmStatusTypeClassType, confirmStatusTypeMapText,
  duibanModelLabelTableColumns,
  RECOG_MODEL_LABEL_JSON
} from "@/views/cmsPage/versionManage/duibanModelLabel/_data"
import EditorModelLabelPopup
  from "@/views/cmsPage/versionManage/duibanModelLabel/_components/EditorModelLabelPopup/index.vue"
import {
  delDuiBanModelLabelListApi,
  postModelLabelInfoApi,
  postModelLabelNotConfirmApi
} from "@/views/cmsPage/versionManage/duibanModelLabel/_apis"
import {computedMustParamsByList, judgePostModelLabel} from "@/views/cmsPage/versionManage/duibanModelLabel/_service"

export default {
  props: ['pagination', 'list'],
  components: { EditorModelLabelPopup },
  data() {
    return {
      confirmStatusTypeClassType,
      tableList: duibanModelLabelTableColumns,
      recogModelLabelJson: RECOG_MODEL_LABEL_JSON,
      coinTypeList: COIN_TYPE_LIST,
    }
  },
  computed: {
    /** 计算JSON */
    computedModelLabelStrToJson() {
      return (value, jsonKey) => {
        if (value) {
          return JSON.parse(value)[jsonKey]
        } else {
          return ''
        }
      }
    },
    computedConfirmStatusTypeMapText() {
      return (value) => confirmStatusTypeMapText(value)
    },
  },
  methods: {
    /** 不确定标记 */
    async handleNotConfirm(id) {
      this.$loading.show()
      const res = await postModelLabelNotConfirmApi({id: id})
      this.$loading.hide()
      if (res.status !== '200') return
      this.$message.info('标记成功')
      this.handleEditorSuccess()
    },
    /** 删除 */
    async handleDelLabel(id) {
      this.$loading.show()
      const res = await delDuiBanModelLabelListApi(id)
      this.$loading.hide()
      if (res.status !== '200') return
      this.$message.info('删除成功')
      this.handleEditorSuccess({
        id: id,
        type: 'del'
      })
    },
    /** 编辑正面面文 */
    handleChangeEditor(row, type) {
      const mustParams = {
        coinId: row.coinId,
        frontBackSide: row.frontBackSide,
        clarity: row.clarity,
        angle: row.angle,
        rust: row.rust
      }
      this.$refs.editorModelLabelPopupEl.show(row, type, mustParams)
    },
    /** 编辑成功 */
    handleEditorSuccess(data) {
      if (data) {
        this.$emit('success', data)
      } else {
        this.$emit('success')
      }
    },
    /** 翻页 */
    changePage(page) {
      this.$emit("changePage", page);
    },
    /** 快速操作正反面图 */
    /** 提交数据 */
    async handleConfirm(jsonValue, jsonKey, row) {
      if (jsonKey !== 'coinType') {
        if (!row.coinType) return this.$message.warn('请先选择钱币类型')
      }
      const tempCoinType = jsonKey === 'coinType' ? jsonValue.value : row.coinType
      const tempPostJson = judgePostModelLabel(jsonValue, jsonKey, row.labelValue, tempCoinType)
      if (jsonKey !== 'coinType') {
        if (!tempPostJson['正面面文']) {
          if (row.coinName) {
            tempPostJson['正面面文'] = row.coinName
          } else {
            tempPostJson['正面面文'] = ''
          }
        }
      }
      let tempMustParams = {}
      if (jsonKey !== 'coinType') {
        const mustParams = {coinId: row.coinId, frontBackSide: row.frontBackSide, clarity: row.clarity, angle: row.angle, rust: row.rust}
        tempMustParams = computedMustParamsByList(jsonKey, jsonValue, mustParams)
      } else {
        tempMustParams = {coinId: 0, frontBackSide: 0, clarity: 0, angle: 0, rust: 0}
      }
      const res = await postModelLabelInfoApi({
        id: row.id,
        coinType: tempCoinType,
        ...tempMustParams,
        attributeVo: tempPostJson
      })
      if (res.status !== '200') return
      this.handleEditorSuccess({
        id: row.id,
        jsonStr: tempPostJson
      })
    },
    handlePreviewImg(images, index) {
      const urls = images.map(el => {
        return {img_url: el}
      })
      this.$previewImg({
        list: urls,
        baseImgField: "img_url",
        showMute: false,
        current: index
      });
    }
  }
}
</script>
<style lang="scss" scoped>
</style>