var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("a-table", {
        attrs: {
          columns: _vm.tableList,
          pagination: _vm.pagination,
          "data-source": _vm.list,
          rowKey: function(record, index) {
            return index
          }
        },
        on: { change: _vm.changePage },
        scopedSlots: _vm._u(
          [
            {
              key: "itemCutPicSlot",
              fn: function(row) {
                return _c("div", {}, [
                  _c("img", {
                    staticClass: "w-500",
                    attrs: { src: row.itemImage, alt: "" },
                    on: {
                      click: function($event) {
                        return _vm.handlePreviewImg([row.itemImage], 0)
                      }
                    }
                  }),
                  _c("div", { staticClass: "mt-10 color-gray" }, [
                    _vm._v("【" + _vm._s(row.id) + "】" + _vm._s(row.coinName))
                  ])
                ])
              }
            },
            {
              key: "itemCutPicImgSlot",
              fn: function(row) {
                return _c("div", {}, [
                  _c("img", {
                    staticClass: "w-100 h-100",
                    attrs: { src: row.cutItemImage, alt: "" },
                    on: {
                      click: function($event) {
                        return _vm.handlePreviewImg([row.cutItemImage], 0)
                      }
                    }
                  })
                ])
              }
            },
            {
              key: "itemConfirmStatusSlot",
              fn: function(row) {
                return _c("div", {}, [
                  _c(
                    "div",
                    { class: _vm.confirmStatusTypeClassType[row.isConfirm] },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.computedConfirmStatusTypeMapText(row.isConfirm)
                        )
                      )
                    ]
                  )
                ])
              }
            },
            {
              key: "itemCoinTypeSlot",
              fn: function(row) {
                return _c(
                  "div",
                  {},
                  [
                    row.coinType
                      ? _vm._l(_vm.coinTypeList, function(tag, key) {
                          return _c(
                            "a-popconfirm",
                            {
                              key: key,
                              attrs: {
                                title:
                                  "重新修改类型会覆盖之前的标注记录，需要重新标注！，是否确定修改？",
                                "ok-text": "Yes",
                                "cancel-text": "No"
                              },
                              on: {
                                confirm: function($event) {
                                  return _vm.handleConfirm(tag, "coinType", row)
                                }
                              }
                            },
                            [
                              _c(
                                "a-tag",
                                {
                                  staticClass:
                                    "cur-pot mb-5 w-80 h-30 mr-10 font-size-14 text-center color-black",
                                  class:
                                    tag.value === row.coinType
                                      ? "color-blue"
                                      : "",
                                  attrs: {
                                    color:
                                      tag.value === row.coinType ? "blue" : ""
                                  }
                                },
                                [_vm._v(_vm._s(tag.name))]
                              )
                            ],
                            1
                          )
                        })
                      : _vm._l(_vm.coinTypeList, function(tag, key) {
                          return _c(
                            "a-tag",
                            {
                              key: key,
                              staticClass:
                                "cur-pot mb-5 w-80 h-30 mr-10 font-size-14 text-center color-black",
                              class:
                                tag.value === row.coinType ? "color-blue" : "",
                              attrs: {
                                color: tag.value === row.coinType ? "blue" : ""
                              },
                              on: {
                                click: function($event) {
                                  return _vm.handleConfirm(tag, "coinType", row)
                                }
                              }
                            },
                            [_vm._v(_vm._s(tag.name))]
                          )
                        })
                  ],
                  2
                )
              }
            },
            {
              key: "itemImgFrontOrBackSlot",
              fn: function(row) {
                return row.coinType
                  ? _c("div", {}, [
                      _c(
                        "div",
                        { staticClass: "mt-10" },
                        [
                          _vm.computedModelLabelStrToJson(
                            row.labelValue,
                            "正面面文"
                          )
                            ? _c(
                                "div",
                                { staticClass: "color-blue font-weight-bold" },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.computedModelLabelStrToJson(
                                        row.labelValue,
                                        "正面面文"
                                      )
                                    )
                                  )
                                ]
                              )
                            : _c("div", [
                                _vm._v(_vm._s(row.coinName) + " "),
                                row.coinPercentage
                                  ? _c("span", { staticClass: "color-green" }, [
                                      _vm._v(
                                        "（" + _vm._s(row.coinPercentage) + "）"
                                      )
                                    ])
                                  : _vm._e()
                              ]),
                          _c(
                            "a-button",
                            {
                              staticClass: "mt-10",
                              attrs: {
                                type: "primary",
                                size: "small",
                                icon: "edit"
                              },
                              on: {
                                click: function($event) {
                                  return _vm.handleChangeEditor(row, "正面面文")
                                }
                              }
                            },
                            [_vm._v("编辑正面面文")]
                          ),
                          _c(
                            "div",
                            { staticClass: "mt-10" },
                            [
                              _c(
                                "a-popconfirm",
                                {
                                  attrs: {
                                    title: "确定标记为不确定？",
                                    "ok-text": "Yes",
                                    "cancel-text": "No"
                                  },
                                  on: {
                                    confirm: function($event) {
                                      return _vm.handleNotConfirm(row.id)
                                    }
                                  }
                                },
                                [
                                  _c(
                                    "a-button",
                                    {
                                      attrs: { type: "danger", size: "small" }
                                    },
                                    [_vm._v("不确定")]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ])
                  : _vm._e()
              }
            },
            {
              key: "itemTagByFrontOrBackSlot",
              fn: function(row) {
                return row.coinType
                  ? _c(
                      "div",
                      {},
                      [
                        _c("div", { staticClass: "mb-10 color-red" }, [
                          _vm._v("正反面")
                        ]),
                        _vm._l(
                          _vm.recogModelLabelJson[row.coinType]["正反面"],
                          function(tag, key) {
                            return _c(
                              "a-tag",
                              {
                                key: key,
                                staticClass:
                                  "cur-pot mb-5 w-80 h-30 mr-10 font-size-14 text-center color-black",
                                class:
                                  tag ===
                                  _vm.computedModelLabelStrToJson(
                                    row.labelValue,
                                    "正反面"
                                  )
                                    ? "color-blue"
                                    : "",
                                attrs: {
                                  color:
                                    tag ===
                                    _vm.computedModelLabelStrToJson(
                                      row.labelValue,
                                      "正反面"
                                    )
                                      ? "blue"
                                      : ""
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.handleConfirm(tag, "正反面", row)
                                  }
                                }
                              },
                              [_vm._v(_vm._s(tag))]
                            )
                          }
                        )
                      ],
                      2
                    )
                  : _vm._e()
              }
            },
            {
              key: "itemImgQualitySlot",
              fn: function(row) {
                return row.coinType
                  ? _c(
                      "div",
                      {},
                      [
                        _c("div", { staticClass: "mb-10 color-red" }, [
                          _vm._v("清晰度")
                        ]),
                        _vm._l(
                          _vm.recogModelLabelJson[row.coinType]["清晰度"],
                          function(tag, key) {
                            return _c(
                              "a-tag",
                              {
                                key: key,
                                staticClass:
                                  "cur-pot mb-5 w-80 h-30 mr-10 font-size-14 text-center color-black",
                                class:
                                  tag ===
                                  _vm.computedModelLabelStrToJson(
                                    row.labelValue,
                                    "清晰度"
                                  )
                                    ? "color-blue"
                                    : "",
                                attrs: {
                                  color:
                                    tag ===
                                    _vm.computedModelLabelStrToJson(
                                      row.labelValue,
                                      "清晰度"
                                    )
                                      ? "blue"
                                      : ""
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.handleConfirm(tag, "清晰度", row)
                                  }
                                }
                              },
                              [_vm._v(_vm._s(tag))]
                            )
                          }
                        )
                      ],
                      2
                    )
                  : _vm._e()
              }
            },
            {
              key: "itemImgAngleSlot",
              fn: function(row) {
                return row.coinType
                  ? _c(
                      "div",
                      {},
                      [
                        _c("div", { staticClass: "mb-10 color-red" }, [
                          _vm._v("角度")
                        ]),
                        _vm._l(
                          _vm.recogModelLabelJson[row.coinType]["角度"],
                          function(tag, key) {
                            return _c(
                              "a-tag",
                              {
                                key: key,
                                staticClass:
                                  "cur-pot mb-5 w-80 h-30 mr-10 font-size-14 text-center color-black",
                                class:
                                  tag ===
                                  _vm.computedModelLabelStrToJson(
                                    row.labelValue,
                                    "角度"
                                  )
                                    ? "color-blue"
                                    : "",
                                attrs: {
                                  color:
                                    tag ===
                                    _vm.computedModelLabelStrToJson(
                                      row.labelValue,
                                      "角度"
                                    )
                                      ? "blue"
                                      : ""
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.handleConfirm(tag, "角度", row)
                                  }
                                }
                              },
                              [_vm._v(_vm._s(tag))]
                            )
                          }
                        )
                      ],
                      2
                    )
                  : _vm._e()
              }
            },
            {
              key: "itemImgFeatureSlot",
              fn: function(row) {
                return row.coinType
                  ? _c(
                      "div",
                      {},
                      _vm._l(
                        _vm.recogModelLabelJson[row.coinType]["特征"],
                        function(tag, key) {
                          return _c(
                            "a-tag",
                            {
                              key: key,
                              staticClass:
                                "cur-pot mb-5 w-80 h-30 mr-10 font-size-14 text-center color-black",
                              class:
                                tag ===
                                _vm.computedModelLabelStrToJson(
                                  row.labelValue,
                                  "特征"
                                )
                                  ? "color-blue"
                                  : "",
                              attrs: {
                                color:
                                  tag ===
                                  _vm.computedModelLabelStrToJson(
                                    row.labelValue,
                                    "特征"
                                  )
                                    ? "blue"
                                    : ""
                              },
                              on: {
                                click: function($event) {
                                  return _vm.handleConfirm(tag, "特征", row)
                                }
                              }
                            },
                            [_vm._v(_vm._s(tag))]
                          )
                        }
                      ),
                      1
                    )
                  : _vm._e()
              }
            },
            {
              key: "itemImgRustStatusSlot",
              fn: function(row) {
                return row.coinType
                  ? _c(
                      "div",
                      {},
                      [
                        _c("div", { staticClass: "mb-10 color-red" }, [
                          _vm._v("锈迹")
                        ]),
                        _vm._l(
                          _vm.recogModelLabelJson[row.coinType]["锈迹"],
                          function(tag, key) {
                            return _c(
                              "a-tag",
                              {
                                key: key,
                                staticClass:
                                  "cur-pot mb-5 w-80 h-30 mr-10 font-size-14 text-center color-black",
                                class:
                                  tag ===
                                  _vm.computedModelLabelStrToJson(
                                    row.labelValue,
                                    "锈迹"
                                  )
                                    ? "color-blue"
                                    : "",
                                attrs: {
                                  color:
                                    tag ===
                                    _vm.computedModelLabelStrToJson(
                                      row.labelValue,
                                      "锈迹"
                                    )
                                      ? "blue"
                                      : ""
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.handleConfirm(tag, "锈迹", row)
                                  }
                                }
                              },
                              [_vm._v(_vm._s(tag))]
                            )
                          }
                        )
                      ],
                      2
                    )
                  : _vm._e()
              }
            },
            {
              key: "itemImgKaiYuanStatusSlot",
              fn: function(row) {
                return row.coinType
                  ? _c(
                      "div",
                      {},
                      [
                        _c("div", [
                          _vm._v(
                            _vm._s(
                              _vm.computedModelLabelStrToJson(
                                row.labelValue,
                                "会昌"
                              )
                            )
                          )
                        ]),
                        row.coinId === 1
                          ? _c(
                              "a-button",
                              {
                                staticClass: "mt-10",
                                attrs: {
                                  type: "primary",
                                  size: "small",
                                  icon: "edit"
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.handleChangeEditor(row, "会昌")
                                  }
                                }
                              },
                              [_vm._v("编辑会昌")]
                            )
                          : _vm._e()
                      ],
                      1
                    )
                  : _vm._e()
              }
            },
            {
              key: "itemImgCreateCoinTypeSlot",
              fn: function(row) {
                return row.coinType
                  ? _c(
                      "div",
                      {},
                      [
                        _c("div", [
                          _vm._v(
                            _vm._s(
                              _vm.computedModelLabelStrToJson(
                                row.labelValue,
                                "铸局"
                              )
                            )
                          )
                        ]),
                        ["circle_coin"].includes(row.coinType)
                          ? _c(
                              "a-button",
                              {
                                staticClass: "mt-10",
                                attrs: {
                                  type: "primary",
                                  size: "small",
                                  icon: "edit"
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.handleChangeEditor(row, "铸局")
                                  }
                                }
                              },
                              [_vm._v("编辑铸局")]
                            )
                          : _vm._e()
                      ],
                      1
                    )
                  : _vm._e()
              }
            },
            {
              key: "itemImgUnearthedRelicSlot",
              fn: function(row) {
                return row.coinType
                  ? _c(
                      "div",
                      {},
                      [
                        _c("div", [
                          _vm._v(
                            _vm._s(
                              _vm.computedModelLabelStrToJson(
                                row.labelValue,
                                "坑口"
                              )
                            )
                          )
                        ]),
                        ["circle_coin"].includes(row.coinType)
                          ? _c(
                              "a-button",
                              {
                                staticClass: "mt-10",
                                attrs: {
                                  type: "primary",
                                  size: "small",
                                  icon: "edit"
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.handleChangeEditor(row, "坑口")
                                  }
                                }
                              },
                              [_vm._v("编辑坑口")]
                            )
                          : _vm._e()
                      ],
                      1
                    )
                  : _vm._e()
              }
            },
            {
              key: "itemModelLabelAdminSlot",
              fn: function(row) {
                return _c("div", {}, [
                  row.sysAdmin
                    ? _c("div", { staticClass: "color-green" }, [
                        _vm._v(_vm._s(row.sysAdmin))
                      ])
                    : _vm._e()
                ])
              }
            },
            {
              key: "itemAuctionSlot",
              fn: function(row) {
                return _c(
                  "div",
                  {},
                  [
                    _c(
                      "a-popconfirm",
                      {
                        attrs: {
                          title: "确定删除当前标记？",
                          "ok-text": "Yes",
                          "cancel-text": "No"
                        },
                        on: {
                          confirm: function($event) {
                            return _vm.handleDelLabel(row.id)
                          }
                        }
                      },
                      [
                        _c(
                          "a-button",
                          { attrs: { type: "danger", size: "small" } },
                          [_vm._v("删除")]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              }
            }
          ],
          null,
          true
        )
      }),
      _c("EditorModelLabelPopup", {
        ref: "editorModelLabelPopupEl",
        on: { editSuccess: _vm.handleEditorSuccess }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }