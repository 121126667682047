import instance from "@/axios"

/** 获取物流信息 */
export const getDuiBanModelLabelListApi = async (params) => {
    return instance({
        url: '/dq_admin/duibanRecoModelLabel/list',
        method: 'GET',
        params: params
    })
}

export const delDuiBanModelLabelListApi = async (params) => {
    return instance({
        url: '/dq_admin/duibanRecoModelLabel/deleteByIds',
        method: 'GET',
        params: {
            ids: params
        }
    })
}

/** 提交特征收集 */
export const postModelLabelInfoApi = async (params) => {
    return instance({
        url: '/dq_admin/duibanRecoModelLabel/updateLabelVaue',
        method: 'post',
        data: params
    })
}
/** 标记为不确定 */
export const postModelLabelNotConfirmApi = async (params) => {
    return instance({
        url: '/dq_admin/duibanRecoModelLabel/updateIsNotConfirm',
        method: 'post',
        data: params
    })
}